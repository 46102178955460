export const renameLangFlag = (lang) => {
  const browserLangs = {
    'kk': 'kz',
    'tg': 'tj',
    'ky': 'kg',
    'hy': 'am',
    'uz': 'uz',
    'ru': 'ru',
    'en': 'en',
    'es': 'es',
    'uk': 'ua',
    'de': 'de',
    'pl': 'pl'
  }
  let res = lang
  for (let key in browserLangs) {
    if (key === lang) {
      res = browserLangs[key]
    }
  }
  return res
}