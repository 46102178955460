import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
const MainPage  = React.lazy(() => import('./pages/MainPage'));
const OrderPage = React.lazy(() => import('./pages/OrderPage'));
const PromoPage = React.lazy(() => import('./pages/PromoPage'));
const SuccessPage = React.lazy(() => import('./pages/SuccessPage'));
const FailPage = React.lazy(() => import('./pages/FailPage'));
const AboutPage = React.lazy(() => import('./pages/AboutPage'));
const SupportPage = React.lazy(() => import('./pages/SupportPage'));
const ContactPage = React.lazy(() => import('./pages/ContactPage'));
const LoadingPage = React.lazy(() => import('./pages/LoadingPage'));
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'));
import { useUrlHash } from '@hooks';


function App() {
    useUrlHash();

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path={'/'} element={<MainPage/>}/>
                <Route path={'/order'} element={<OrderPage/>}/>
                <Route path={'/order/:product'} element={<OrderPage/>}/>
                <Route path={'/promo/:product'} element={<PromoPage/>}/>
                <Route path={'/success/:number'} element={<SuccessPage/>}/>
                <Route path={'/loading'} element={<LoadingPage/>}/>
                <Route path={'/fail/:product'} element={<FailPage/>}/>
                <Route path={'/about'} element={<AboutPage/>}/>
                <Route path={'/support'} element={<SupportPage/>}/>
                <Route path={'/contact'} element={<ContactPage/>}/>
                <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
        </Suspense>
    );
}

export default App;
