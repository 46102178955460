export const availableLangs = (langs = [], fullDomainName = '') => {
  const defaultLangs = ['ru', 'en']
  let newLangs = []

  if (fullDomainName.includes('skilldeposit.am')) {
    newLangs = langs.filter(lang => lang === 'am')
    newLangs.push(...defaultLangs)
  } else if(fullDomainName.includes('skilldeposit.kz')) {
    newLangs = langs.filter(lang => lang === 'kz')
    newLangs.push(...defaultLangs)
  } else if(fullDomainName.includes('skilldeposit.kg')) {
    newLangs = langs.filter(lang => lang === 'kg')
    newLangs.push(...defaultLangs)
  } else if(fullDomainName.includes('skilldeposit.uz')) {
    newLangs = langs.filter(lang => lang === 'uz')
    newLangs.push(...defaultLangs)
  } else {
    newLangs.push(...defaultLangs)
  }

  return newLangs
}