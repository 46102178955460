import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/style.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { TranslateProvider } from './translations/TranslateProvider/TranslateProvider';
import { DataProvider } from './hocs';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <DataProvider>
            <TranslateProvider>
                <App/>
            </TranslateProvider>
        </DataProvider>
    </BrowserRouter>
);
