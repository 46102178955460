import React, { useState, createContext, useEffect } from 'react';
import { useInfo } from '../../hooks/useInfo';
import { useProductsData } from '../../hooks';
import { renameLangFlag } from '../../helpers/renameLangFlag';
import { availableLangs } from '../../helpers/availableLangs';

let fullDomainName = window.location.hostname;
const allLangs = ['ru', 'en', 'am', 'kz', 'kg', 'uz'];

export const DataContext = createContext({
    langs: allLangs,
    lang: 'ru',
    setLang: () => {},
    categories: [],
    price: 1,
    priceFull: 294,
    products: [],
    loading: false,
    currency: 'RUB',
    firstCheckbox: 'Some text for checkbox - 1',
    secondCheckbox: 'Some text for checkbox - 2',
});

export const DataProvider = ({ children }) => {
    const [lang, setLang] = useState('ru');
    const [langs, setLangs] = useState(availableLangs(allLangs, fullDomainName));
    const data = useInfo()
    const { products, loading } = useProductsData();
    let categories = data?.categories
    let price = data?.price
    let priceFull = data?.priceFull
    let currency = data?.currency
    let firstCheckbox = data?.firstCheckbox
    let secondCheckbox = data?.secondCheckbox

    useEffect(() => {
        let storageLang = Object.prototype.hasOwnProperty.call(localStorage, 'localLang')
        if (storageLang) {
          setLang(localStorage.getItem('localLang'));
        } else {
          let browserLang = renameLangFlag(navigator.language.split(/[-_]/)[0]);
          allLangs.includes(browserLang) 
            ? setLang(browserLang)
            : setLang('ru')
        }
    }, []);

    return (
        <DataContext.Provider value={{ lang, setLang, langs, categories, price, priceFull, products, loading, currency, firstCheckbox, secondCheckbox}}>
            {children}
        </DataContext.Provider>
    );
};
